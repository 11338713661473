<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-lg-3 col-md-6 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :model.sync="filters.monthly"
                  :not-list="false"
                  :options="convertArrayToObjectByKey(months, 'id', 'name')"
                  :title="$t('report.monthly') + ':'"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  :required="false"
                  input-max-width="125px"
                  input-width="150px"
                  name="name"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :model.sync="filters.yearly"
                  :not-list="false"
                  :options="yearOptions"
                  :title="$t('report.yearly') + ':'"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  :required="false"
                  input-max-width="125px"
                  input-width="150px"
                  name="name"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :max="1"
                  :model.sync="filters.company_id"
                  :not-list="true"
                  :options="customerOptions"
                  :title="$t('order.customer') + ':'"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  :required="false"
                  input-max-width="125px"
                  input-width="150px"
                  name="order_company_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :item-per-row="1"
                  :max="1"
                  :model.sync="filters.order_id"
                  :not-list="true"
                  :options="orderOptions"
                  :title="$t('general.order') + ':'"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  :required="false"
                  input-max-width="125px"
                  input-width="150px"
                  name="order_id"
                ></custom-multi-select>
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div
          :class="[resizeChartValue == 'piechart' ? 'col-12' : 'col-6']"
          v-if="resizeChartValue != 'linechart'"
        >
          <dashboard-box :title="$t('report.endorsemet_report')">
            <template v-slot:preview>
              <endorsement-report-pie-chart
                :start-date.sync="filters.start_date"
                :end-date.sync="filters.end_date"
                :currency-rate-options="currencyRateOptions"
                :company-id="filters.company_id"
                :order-id="filters.order_id"
                :onClickButton="onClickButton"
                :monthly="filters.monthly"
                :yearly="filters.yearly"
                @resizecard="resizeCard"
              ></endorsement-report-pie-chart>
            </template>
          </dashboard-box>
        </div>
        <div
          :class="[resizeChartValue == 'linechart' ? 'col-12' : 'col-6']"
          v-if="resizeChartValue != 'piechart'"
        >
          <dashboard-box :title="$t('report.endorsemet_report')">
            <template v-slot:preview>
              <endorsement-report-chart
                :start-date.sync="filters.start_date"
                :end-date.sync="filters.end_date"
                :currency-rate-options="currencyRateOptions"
                :company-id="filters.company_id"
                :order-id="filters.order_id"
                :onClickButton="onClickButton"
                :monthly="filters.monthly"
                :yearly="filters.yearly"
                @resizecard="resizeCard"
              ></endorsement-report-chart>
            </template>
          </dashboard-box>
        </div>
      </div>

      <dashboard-box>
        <template v-slot:preview>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="fields"
              :hover="false"
              :items="formattedReport"
              :no-border-collapse="true"
              :outlined="false"
              :striped="true"
              :disabled-infinite-loading="false"
              :infiniteId="infiniteId"
              :sticky-header="true"
              tableVariant="''"
              :page.sync="page"
              :perPage.sync="perPage"
              @hitBottom="onHitBottom"
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              :responsive="true"
            ></data-table>
            <div class="row">
              <div
                class="general-area col-2 d-flex align-items-center justify-content-start align-content-start"
              >
                <div class="text-center">{{ $t("general.total") }}</div>
              </div>
              <div
                class="col-10 info-total-area align-items-center d-flex justify-content-end align-content-end"
              >
                <div class="col-4 text-center mr-7">
                  {{ $t("report.loaded_quantity") }}:
                  <span class="total-area-text">
                    {{
                      Number(totalData.total_packing_amount).toLocaleString("tr-TR", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    }}
                  </span>
                </div>
                <div class="col-4 text-center mr-7">
                  {{ $t("report.currency_indorsement") }}:

                  <span
                    v-for="(totalAmount, key) in totalData.total_currency_endorsement"
                    :key="key"
                  >
                    <br />{{ key }}:
                    <span class="total-area-text">
                      {{
                        Number(totalAmount).toLocaleString("tr-TR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                    </span>
                  </span>
                </div>
                <div class="col-4 text-center mr-0">
                  {{ $t("report.currency_tl_indorsement") }}:
                  <span class="total-area-text">
                    {{
                      Number(totalData.total_tl_endorsement).toLocaleString("tr-TR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import EndorsementReportChart from "./EndorsementReportChart/EndorsementReportChart";
import EndorsementReportPieChart from "./EndorsementReportChart/EndorsementReportPieChart";

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  FILTER,
} from "@/core/services/store/report/endorsementReport.module";

import { GET_ITEMS, GET_ITEMS as REST_GET_ITEM } from "@/core/services/store/REST.module";
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  ENDORSEMENT_REPORT_START_DATE_STORAGE_NAME,
  ENDORSEMENT_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

export default {
  name: "EndorsementReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect,
    ContextMenu,
    EndorsementReportChart,
    EndorsementReportPieChart,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    onClickResizeControl() {
      if (this.resizeBool != true) {
        return this.resize();
      } else {
        return;
      }
    },
    formattedReport() {
      if (!this.items || this.items.data === null || this.items.data === undefined)
        return [];
      let temp = [];
      this.items.data.forEach((item) => {
        let findCurrency = _.find(this.currencyRateOptions, {
          code: item.currency_unit.code,
        });
        let sumPackingListAmount = _.sumBy(item.packing, function (o) {
          return o.amount;
        });
        let endorsement = Number(sumPackingListAmount) * Number(item.sale_price);
        let endorsementTL = endorsement * Number(findCurrency.rate);
        let salePrice = Number(item.sale_price);
        let loadedDate = "";
        if (item.packing[0]) {
          loadedDate = moment(item.packing[0].created_at).format(
            MOMENT_SYSTEM_DATE_FORMAT
          );
        }
        temp.push({
          customer: item.company.name,
          order_number: item.order_number,
          amount: sumPackingListAmount,
          loaded_date: loadedDate,
          sale_price: salePrice.toLocaleString("tr-TR") + " " + item.currency_unit.code,
          currency_indorsement:
            endorsement.toLocaleString("tr-TR") + " " + item.currency_unit.code,
          currency_tl_indorsement:
            endorsementTL.toLocaleString("tr-TR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " ₺",
        });
      });
      return temp;
    },
    yearOptions() {
      const currentYear = new Date().getFullYear();
      const years = [];
      const yearsToGenerate = 60;

      for (let i = 0; i < yearsToGenerate; i++) {
        const year = currentYear - i;
        years.push({ id: year, name: year });
      }
      return this.convertArrayToObjectByKey(years, "id", "name");
    },
  },
  data() {
    return {
      // Filters & pagination
      totalAreaLoading: false,
      infiniteId: 1,
      sort: "desc",
      order: "id",
      resizeChartValue: null,
      sampleStatuses: [],
      fieldsToViewEdit: [],
      customerOptions: [],
      search: null,
      onClickButton: false,
      paginate: [],
      page: 1,
      perPage: 25,
      currentStoreType: 99999999,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      download: false,
      reportListUrl: "api/report/endorsement",
      currencyRateOptions: [],
      companies: [],
      orderOptions: [],
      totalData: [],
      months: [
        {
          id: "01",
          name: this.$t("dateTime.january_full"),
        },
        {
          id: "02",
          name: this.$t("dateTime.february_full"),
        },
        {
          id: "03",
          name: this.$t("dateTime.march_full"),
        },
        {
          id: "04",
          name: this.$t("dateTime.april_full"),
        },
        {
          id: "05",
          name: this.$t("dateTime.may_full"),
        },
        {
          id: "06",
          name: this.$t("dateTime.june_full"),
        },
        {
          id: "07",
          name: this.$t("dateTime.july_full"),
        },
        {
          id: "08",
          name: this.$t("dateTime.august_full"),
        },
        {
          id: "09",
          name: this.$t("dateTime.september_full"),
        },
        {
          id: "10",
          name: this.$t("dateTime.october_full"),
        },
        {
          id: "11",
          name: this.$t("dateTime.november_full"),
        },
        {
          id: "12",
          name: this.$t("dateTime.december_full"),
        },
      ],
      fields: [
        {
          key: "customer",
          label: this.$t("report.customer"),
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "order_number",
          label: this.$t("report.order_number"),
          sortable: true,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "amount",
          label: this.$t("report.loaded_quantity"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "loaded_date",
          label: this.$t("report.loaded_date"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "sale_price",
          label: this.$t("report.sale_price"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "currency_indorsement",
          label: this.$t("report.currency_indorsement"),
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "currency_tl_indorsement",
          label: this.$t("report.currency_tl_indorsement"),
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
      ],
    };
  },
  methods: {
    resizeCard(val) {
      this.resizeChartValue = val;
    },
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    filterResult() {
      localStorage.setItem(
        ENDORSEMENT_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        ENDORSEMENT_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.onHitBottom();
      this.getTotalAmount();
    },
    getTotalAmount() {
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);
      this.$set(filters, "monthly", JSON.stringify(filters.monthly));
      this.$set(filters, "yearly", JSON.stringify(filters.yearly));
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/report/endorsement-total-data",
          filters: filters,
        })
        .then((result) => {
          if (result.status) {
            self.totalData = result.data;
          } else {
            console.log({ error: result });
          }
        });
    },
    getBottomTotals() {
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);
      this.$set(filters, "monthly", JSON.stringify(filters.monthly));
      this.$set(filters, "yearly", JSON.stringify(filters.yearly));

      self.$store
        .dispatch(GET_ITEMS, {
          url: "api/report/endorsement-total-data",
          filters: filters,
        })
        .then((result) => {
          console.log(result);
        });
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      }
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );

      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);
      this.$set(filters, "monthly", JSON.stringify(filters.monthly));
      this.$set(filters, "yearly", JSON.stringify(filters.yearly));

      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;
          if (result.status) {
            this.infiniteId++;
            if ($state) {
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },
    resetFilters() {
      (this.sort = "desc"),
        (this.order = "id"),
        this.setFilter({
          page: 1,
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });
    },
    setup() {
      let self = this,
        promises = [];
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/companies",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/currency-rate",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/report/order-list",
        })
      );

      Promise.all(promises).then((results) => {
        if (results[0].status) {
          self.customerOptions = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "name"
          );
        }
        if (results[1].status) {
          self.currencyRateOptions = results[1].data.data;
        }
        if (results[2].status) {
          self.orderOptions = results[2].data;
        }
      });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.endorsement_report") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      ENDORSEMENT_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(ENDORSEMENT_REPORT_END_DATE_STORAGE_NAME))
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      ENDORSEMENT_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(ENDORSEMENT_REPORT_START_DATE_STORAGE_NAME))
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();
    this.onClickButton = true;
    setTimeout(this.filterResult, 500);
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}

.info-total-area {
  margin-bottom: 1%;
  margin-right: -1%;
  padding-right: 5%;
}
.info-total-area > div {
  flex: 0 0 17%; /* Kutuların genişliğini ayarlamak için istediğiniz değeri kullanabilirsiniz */
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.general-area {
  margin-bottom: 1%;
  padding-left: 2%;
}
.general-area > div {
  flex: 1; /* Kutuların genişliğini ayarlamak için istediğiniz değeri kullanabilirsiniz */
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.total-area-text {
  font-size: 13px;
  font-weight: 400;
}
</style>
<style lang="scss">
.b-table-sticky-header > .table.b-table > thead > tr > th {
  background-color: white !important;
}
.b-table-sticky-header {
  max-height: 500px;
}
.b-table-sticky-header > .table.b-table > tbody > tr > th {
  background-color: white !important;
}
</style>
