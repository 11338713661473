<template>
  <div>
    <p class="endorsement-title mb-0 pb-0 d-flex mx-3">
      {{ $t("report.endorsement_report_info_company") }}

      <button-with-icon
        v-if="resizeBool == null || resizeBool == false"
        icon-name="icons.report.enlarge"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
      <button-with-icon
        v-if="resizeBool == true"
        icon-name="icons.report.reduce"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
    </p>
    <div
      class="row loading-area text-center justify-content-center align-items-center"
      v-if="chartLoading"
    >
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!chartLoading" v-on:click="resizeBool ? null : resize()" class="mt-0 pt-0">
      <apexchart
        type="pie"
        :height="height"
        :options="chartOptions"
        :series="series"
        :width="tmpWidth"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import { GET_ITEMS } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
export default {
  name: "EndorsementReportPieChart",
  components: {
    apexchart: VueApexCharts,
    ButtonWithIcon,
  },
  data() {
    return {
      chartCategories: [],
      resizeBool: null,
      tmpSeries: [],
      series: [],
      labels: [],
      height: 235,
      tmpWidth: null,
      chartLoading: false,
    };
  },

  props: [
    "startDate",
    "endDate",
    "currencyRateOptions",
    "companyId",
    "orderId",
    "onClickButton",
    "monthly",
    "yearly",
  ],
  mounted() {
    this.getItems();
  },
  computed: {
    chartOptions() {
      let self = this;
      if (this.resizeBool) {
        this.tmpWidth = window.innerWidth / 2;
      } else {
        this.tmpWidth = window.innerWidth / 3;
      }
      let chartOptions = {
        legend: {
          show: this.resizeBool,
          position: "right",
          fontSize: 16,
          offsetY: 0,
          itemMargin: {
            vertical: 0,
          },
        },
        chart: {
          type: "pie",
          height: this.height,
          width: this.tmpWidth,
        },
        labels: this.labels,
        responsive: [
          {
            options: {
              chart: {
                width: "auto",
                height: "auto",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          y: {
            formatter: function (value) {
              return (
                self.$t("general.amount") + ":" + value.toLocaleString("tr-TR") + " €"
              );
            },
          },
        },
      };
      return chartOptions;
    },
  },

  methods: {
    resize() {
      this.resizeBool = !this.resizeBool;
      if (this.resizeBool) {
        this.height = 400;
        this.tmpWidth = window.innerWidth / 2;
        this.$emit("resizecard", "piechart");
      } else {
        this.height = 235;
        this.tmpWidth = window.innerWidth / 3;
        this.$emit("resizecard", null);
      }
    },
    getItems() {
      this.chartLoading = true;
      this.series = [];
      this.chartCategories = [];
      let filters = {
        start_date: this.startDate,
        end_date: this.endDate,
        company_id: this.companyId,
        order_id: this.orderId,
      };
      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "end_date", moment(this.endDate).format(LARAVEL_DATE_FORMAT));

      if (this.monthly) {
        this.$set(filters, "monthly", JSON.stringify(this.monthly));
      }
      if (this.yearly) {
        this.$set(filters, "yearly", JSON.stringify(this.yearly));
      }

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/endorsement-pie-chart-data",
        })
        .then((result) => {
          this.chartLoading = false;
          if (result.status) {
            let packingWithOrderAmountSeries = [];
            let tmpData = result.data;
            let self = this;
            _.forEach(tmpData, function (chartData, key) {
              let sumPackingListWithSalePrice = 0;
              let sumAmount = 0;

              chartData.forEach((item) => {
                let findCurrency = _.find(self.currencyRateOptions, {
                  code: item.orderable.currency_unit.code,
                });
                sumAmount += item.amount;
                if (findCurrency.code == "EUR") {
                  sumPackingListWithSalePrice +=
                    Number(item.orderable.sale_price) * Number(item.amount);
                } else {
                  sumPackingListWithSalePrice +=
                    (Number(item.orderable.sale_price) * Number(item.amount)) /
                    Number(findCurrency.rate);
                }
              });
              self.labels.push(
                key +
                  "<br/> <b>" +
                  self.$t("report.quantity") +
                  ":</b> " +
                  sumAmount.toLocaleString("tr-TR", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
              );
              self.series.push(Number(sumPackingListWithSalePrice.toFixed(0)));
            });
          } else {
            let tmpresult = result.data.response.data;
            this.sweetAlertError(tmpresult.message);
          }
        });
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        if(this.chartLoading!=true){
          this.series = [];
          this.labels = [];
          this.getItems();
        }
      }
    },
  },
};
</script>
<style scoped>
.endorsement-title {
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  margin-top: 5px;
  color: #5cb7e0;
  justify-content: space-between;
}
.button-open-close {
  border: 0px !important;
  width: 20px;
}
.loading-area {
  height: 200px;
}
</style>
