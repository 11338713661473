<template>
  <div>
    <p class="endorsement-title mb-0 pb-0 d-flex mx-3">
      {{ $t("report.endorsement_report_info_month") }}
      <button-with-icon
        v-if="resizeBool == null || resizeBool == false"
        icon-name="icons.report.enlarge"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
      <button-with-icon
        v-if="resizeBool == true"
        icon-name="icons.report.reduce"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
    </p>
    <div
      class="row loading-area text-center justify-content-center align-items-center"
      v-if="chartLoading"
    >
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      v-if="!chartLoading"
      v-on:click="resizeBool ? null : resize()"
      :class="{ 'graphic-container': resizeBool }"
      class="mt-0 pt-0"
    >
      <apexchart
        type="line"
        :height="height"
        :options="chartOptions"
        :series="series"
        :width="tmpWidth"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import { GET_ITEMS } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
export default {
  name: "EndorsementReportChart",
  components: {
    apexchart: VueApexCharts,
    ButtonWithIcon,
  },
  data() {
    return {
      height: 200,
      width: null,
      chartCategories: [],
      tmpSeries: [],
      series: [],
      chartLoading: false,
      resizeBool: false,
      getDataStatus:false,
    };
  },
  computed: {
    lineLength() {},
    chartOptions() {
      let seriesLength = this.series[0].data.length / 30;
      if (seriesLength <= 1) {
        seriesLength = 0.8;
      }
      if (this.resizeBool) {
        this.tmpWidth = window.innerWidth * seriesLength;
      } else {
        let widhMiniScreen = 0;
        if (window.innerWidth <= 1500) {
          widhMiniScreen = 2.8;
        } else {
          widhMiniScreen = 3.1;
        }
        this.tmpWidth = window.innerWidth / widhMiniScreen;
      }
      let self = this;
      let tmpChartOptions = {
        defaultLocale: "tr",
        colors: ["#572D80", "#4CAF50"],
        chart: {
          type: "line",
          height: this.height,
          width: this.tmpWidth,
          toolbar: {
            show: false,
            tools: {
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },

        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "50%",
            barHeight: "20%",
            barPadding: 2, // Sütunlar arasındaki boşluk (örneğin, 4 piksel)
            barWidth: "25%", // Sütunların genişliği yüzde olarak belirtilir (örneğin, %80 genişlik)
          },
        },
        dataLabels: {
          enabled: this.resizeBool,
          enabledOnSeries: [1],
          textAnchor: "middle",
          style: {
            fontSize: "10px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
          formatter: function (val, opt) {
            let tmpVal = Number(val).toLocaleString("tr-TR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
            return tmpVal;
          },
          // offsetY: 60,
          dropShadow: {
            enabled: true,
          },
        },
        stroke: {
          show: true,
          width: [0, 2],
          curve: "smooth",
          fill: {
            type: "solid",
            opacity: 0.5,
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
            },
          },
        },
        xaxis: {
          categories: this.chartCategories,
          trim: false,
          minHeight: 200,
          rotate: -90,
          labels: {
            show: this.resizeBool,
          },
          style: {
            fontSize: "12px",
            display: "inline-block",
            overflow: "hidden",
            fontWeight: 500,
          },
          title: {
            text: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: [
          {
            title: {
              text: "Ciro",
            },
            labels: {
              formatter: (val) => {
                return val.toLocaleString("tr-TR") + " " + "€";
              },
            },
          },
          {
            title: {
              text: this.$t("report.quantity"),
            },
            opposite: true,
            labels: {
              formatter: (val) => {
                return val.toLocaleString("tr-TR");
              },
            },
          },
        ],
        fill: {
          type: "gradient",
          gradient: {
            gradientToColors: ["#7DC4E4"],
            type: "vertical",
          },
        },
        tooltip: {
          y: [
            {
              formatter: function (val) {
                return val.toLocaleString("tr-TR") + " " + "€";
              },
            },
            {
              formatter: function (val) {
                return val.toLocaleString("tr-TR") + " " + self.$t("report.quantity");
              },
            },
          ],
        },
      };
      return tmpChartOptions;
    },
  },
  props: [
    "startDate",
    "endDate",
    "currencyRateOptions",
    "companyId",
    "orderId",
    "onClickButton",
    "monthly",
    "yearly",
  ],
  mounted() {
    this.getItems();
  },

  methods: {
    resize() {
      let seriesLength = this.series[0].data.length / 30;

      if (seriesLength <= 1) {
        seriesLength = 0.8;
      }
      this.resizeBool = !this.resizeBool;
      if (this.resizeBool) {
        this.height = 400;
        this.tmpWidth = window.innerWidth * seriesLength;

        this.$emit("resizecard", "linechart");
      } else {
        let widhMiniScreen = 0;
        if (window.innerWidth <= 1500) {
          widhMiniScreen = 2.8;
        } else {
          widhMiniScreen = 3.1;
        }
        this.height = 200;
        this.tmpWidth = window.innerWidth / widhMiniScreen;
        this.$emit("resizecard", null);
      }
    },
    getItems() {
      this.chartLoading = true;
      this.series = [];
      this.chartCategories = [];
      let filters = {
        start_date: this.startDate,
        end_date: this.endDate,
        company_id: this.companyId,
        order_id: this.orderId,
      };
      if (this.monthly) {
        this.$set(filters, "monthly", JSON.stringify(this.monthly));
      }
      if (this.yearly) {
        this.$set(filters, "yearly", JSON.stringify(this.yearly));
      }
      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "end_date", moment(this.endDate).format(LARAVEL_DATE_FORMAT));

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/endorsement-chart-data",
        })
        .then((result) => {
          this.chartLoading = false;

          let packingWithOrderAmountSeries = [];
          let packingAmountSeries = [];
          let tmpData = result.data;
          let self = this;
          _.forEach(tmpData, function (chartData, key) {
            self.chartCategories.push(key);
            let sumPackingListWithSalePrice = 0;
            let tmpSumPackingList = 0;

            sumPackingListWithSalePrice = Number(
              chartData["sumPackingListWithSalePrice"]
            );
            // Eğer Faturalandırılmış yani muhesebeye gönderilmişse bu toplama işlemi yapılıyor
            tmpSumPackingList = Number(chartData["tmpSumPackingList"]);

            packingWithOrderAmountSeries.push(sumPackingListWithSalePrice.toFixed(4));
            self.tmpSeries.push(sumPackingListWithSalePrice.toFixed(4));
            packingAmountSeries.push(tmpSumPackingList.toFixed(4));
          });
          let tmpPackingWithOrderSeries = {
            name: this.$t("report.endorsement"),
            data: packingWithOrderAmountSeries,
            type: "column",
          };
          let tmpPackingSeries = {
            name: this.$t("report.quantity"),
            data: packingAmountSeries,
            type: "line",
          };
          this.series.push(tmpPackingWithOrderSeries);
          this.series.push(tmpPackingSeries);
        });
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        if(this.chartLoading!=true){
          this.series = [];
          this.chartCategories = [];
          this.getItems();
        }
      }
    },
  },
};
</script>
<style scoped>
.endorsement-title {
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  margin-top: 5px;
  color: #5cb7e0;
  justify-content: space-between;
}
.graphic-container {
  overflow-x: scroll;
  display: flex;
  position: relative;
}
.button-open-close {
  border: 0px !important;
  width: 20px;
}
.loading-area {
  height: 200px;
}
</style>
